import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axiosInstance from "../api/axiosInstance";
import Button from "../components/Button";
import ActivatedButton from "../components/ActivatedButton";
import axios from "axios";
import Headmenu from "../components/headmenu";

export default function AdminPanelApplicationDetails() {

    const {applicationId} = useParams()

    const [applicationData, setApplicationData] = useState(null)
    const [applicationStatuses, setApplicationStatuses] = useState(null)

    useEffect(() => {
        axiosInstance.get(`applications/details/${applicationId}`)
            .then((response) => {
                setApplicationData(response.data)
                console.log(response.data)
            })
            .catch((error) => {
                console.error(error.response)
                alert("Возникла ошибка")
            })

        axiosInstance.get('statuses')
            .then((response) => {
                setApplicationStatuses(response.data)
            })
            .catch((error) => {
                console.error(error.response)
                alert("Возникла ошибка")
            })
    }, []);

    return (
        <>
            <Headmenu />
            <div className={'container m-auto'}>
                {
                    applicationData && (
                        <div className={'m-auto w-1/2'}>
                            <div className={'m-auto mt-20 border border-blue-500 p-20'}>
                                <p className={'text-4xl text-blue-500 mb-5'}>Детали заявки №{applicationId}</p>
                                <div className={'flex flex-col gap-3'}>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Личный номер</b></p>
                                        <p>{applicationData.nakdi_number}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Данные заявителя</b></p>
                                        <p>{applicationData.applicant_data}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Email</b></p>
                                        <p>{applicationData.email}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Номер телеофна</b></p>
                                        <p>{applicationData.phone}</p>
                                    </div>

                                    {
                                        applicationData.shipment_size ? (
                                            <div className={'flex justify-between items-center text-end'}>
                                                <p><b>Размер отправления</b></p>
                                                <p>{applicationData.shipment_size.name}</p>
                                            </div>
                                        ) : (
                                            <div className={'flex justify-between items-center text-end'}>
                                                <p><b>Кол-во свидетелей</b></p>
                                                <p>{applicationData.amount}</p>
                                            </div>
                                        )
                                    }

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Гриф</b></p>
                                        <p>{applicationData.classification.name}</p>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Дополнительные функции</b></p>
                                        <div>{
                                            applicationData.additional_functions.map((item) =>
                                                <p>{item.name}</p>
                                            )
                                        }</div>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Маршрут</b></p>
                                        <div>
                                            <p>{applicationData.from_address}</p>
                                            <i className='bx bx-down-arrow-alt'></i>
                                            <p>{applicationData.to_address}</p>
                                        </div>
                                    </div>

                                    <div className={'flex justify-between items-center text-end'}>
                                        <p><b>Объект отправки</b></p>
                                        <div>{
                                            applicationData.shipment_types.map((item) =>
                                                <p>{item.name}</p>
                                            )
                                        }</div>
                                    </div>
                                </div>
                            </div>

                            <div className={'pe-20 ps-20 pt-5 pb-5'}>
                                <p>Смена статуса заявки</p>
                                <div className={'flex gap-5'}>
                                {
                                        applicationStatuses && (
                                            applicationStatuses.map((item) =>
                                            applicationData.status.id === item.id ? (
                                                <ActivatedButton>{item.name}</ActivatedButton>
                                            ) : (
                                                <Button onClick={() => {
                                                    axiosInstance.post('/statuses/', {
                                                        application_id: applicationData.id,
                                                        status_id: item.id
                                                    })
                                                        .then((response) => {
                                                            document.location.reload()
                                                        })
                                                        .catch((error) => {
                                                            alert("Ошбика при смене статуса. Авторизуйтесь повторно.")
                                                        })
                                                }}>{item.name}</Button>
                                            )
                                        )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    )
                }

            </div>
        </>
    )
}