import logo from './logo.svg';
import './App.css';
import Accordion from "./components/Accordion";
import TextInputField from "./components/TextInputField";
import {useEffect, useState} from "react";
import axiosInstance from "./api/axiosInstance";
import SelectInputField from "./components/SelectInputField";
import MultiSelectInputField from "./components/MultiSelectInputField";
import AddressInputField from "./components/AddressInputField";
import OptionsPickerField from "./components/OptionsPickerField";
import Button from "./components/Button";
import BeepCodeLink from "./components/BeepCodeLink";
import Headmenu from "./components/headmenu";
import NumberInputField from "./components/NumberInputField";
import {type} from "@testing-library/user-event/dist/type";

function App() {

    const [availableSizes, setAvailableSizes] = useState([]);
    const [availableClassifications, setAvailableClassifications] = useState([]);
    const [availableAdditionalFunctions, setAvailableAdditionalFunctions] = useState([]);
    const [availableShipmentTypes, setAvailableShipmentTypes] = useState([])

    const [error, setError] = useState(null)
    const [done, setDone] = useState(false)


    // APPLICATION DATA
    const [nakdi, setNakdi] = useState(null);
    const [applicant, setApplicant] = useState(null);
    const [size, setSize] = useState(null);
    const [classification, setClassification] = useState(null);
    const [additionalFunctions, setAdditionalFunctions] = useState([]);
    const [beep, setBeep] = useState(null);
    const [pointA, setPointA] = useState(null);
    const [pointB, setPointB] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);

    const [amount, setAmount] = useState(null);

    const [types, setTypes] = useState(null);
    const [reward, setReward] = useState(null);

    const [applicationId, setApplicationId] = useState(0)

    useEffect(() => {
        axiosInstance.get('sizes/')
            .then((response) => {
                setAvailableSizes(response.data)
            })

        axiosInstance.get('classifications/')
            .then((response) => {
                setAvailableClassifications(response.data)
            })

        axiosInstance.get('additional-functions/')
            .then((response) => {
                setAvailableAdditionalFunctions(response.data)
            })

        axiosInstance.get('types/')
            .then((response) => {
                setAvailableShipmentTypes(response.data)
            })
    }, [])

    const createApplication = () => {
        setError(null)
        axiosInstance.post("/applications/", {
            nakdi: nakdi,
            applicant_data: applicant,
            from_address: pointA,
            to_address: pointB,
            beep_code: beep,
            additional_functions: additionalFunctions,
            shipment_size: size,
            amount: amount,
            shipment_type: types,
            classification: classification,
            reward: reward,
            email: email,
            phone: phone
        })
            .then((resp) => {
                setApplicationId(resp.data.id)
                setDone(true)
            })
            .catch((err) => {
                setError("Пожалуйста, закончите заполнение формы")
            })
    }

    useEffect(() => {
        console.log(types)
        if (types) {
            if (types[types.length - 1] === "Свидетели" || types[types.length - 1] === "Специалисты") {
                alert("Вернитесь в раздел 'Информация о инициаторе' и укажите количество людей")
            }
        }

    }, [types]);

    return (
        <>
            <Headmenu/>
            <div className={'container m-auto'}>
                {
                    done ? (
                        <div
                            className={'bg-white flex flex-col gap-5 p-20 text-center rounded-xl w-2/3 max-lg:w-full m-auto mt-20'}>
                            <i className='bx bxs-check-circle text-5xl text-green-500'></i>
                            <p className={'text-4xl'}>Заявка успешно отправлена</p>
                            <br/>
                            <p>Номер вашей заявки: {applicationId}</p>

                            <br/>
                            <p>Сохраните номер заявки, чтобы отслеживать ее состояние</p>

                            <br/>
                            <Button onClick={() => {
                                document.location.reload()
                            }}>Отправить еще одну</Button>
                        </div>
                    ) : (
                        <div
                            className={'w-2/3 max-lg:w-full m-auto p-20 max-lg:p-5 flex flex-col gap-10 rounded-xl mt-20'}>
                            <Accordion title={"Информация об инициаторе"}>
                                <div className={'flex flex-col gap-5'}>
                                    <TextInputField setSource={setNakdi} title={'Личный номер'}
                                                    hint={''}
                                                    placeholder={'Введите номер'}/>
                                    <TextInputField setSource={setApplicant} title={'Данные инициатора'}
                                                    hint={'ФИО, ИНН, наименование организации или органа власти'}
                                                    placeholder={'Введите данные инициатора'}/>

                                    <TextInputField setSource={setEmail} title={'Ваш Email'} hint={'Введите ваш Email'}
                                                    placeholder={'Email'}/>
                                    <TextInputField setSource={setPhone} title={'Ваш телефон'}
                                                    hint={'Введите ваш номер телефона'} placeholder={'Номер телефона'}/>


                                </div>
                            </Accordion>

                            <Accordion title={'Информация об отправлении'}>
                                <div className={'flex flex-col gap-5'}>
                                    <div>
                                        <p className={'text-lg'}>Тип отправления</p>
                                        <OptionsPickerField setSource={setTypes} choices={availableShipmentTypes}/>
                                        {
                                            types && (types.includes("Свидетели") || types.includes("Специалисты")) ? (
                                                <NumberInputField setSource={setAmount} title={'Количество людей'}
                                                                  hint={''}
                                                                  placeholder={'Кол-во людей'}/>
                                            ) : (
                                                <SelectInputField setSource={setSize}
                                                                  hint={'Выберите вариант из списка'}
                                                                  title={'Размер отправления'}
                                                                  availableChoices={availableSizes}/>
                                            )
                                        }

                                    </div>


                                    <SelectInputField setSource={setClassification}
                                                      hint={'Выберите уровень конфиденциальности'}
                                                      title={'Гриф'} availableChoices={availableClassifications}/>
                                    <MultiSelectInputField setSource={setAdditionalFunctions}
                                                           hint={'Выберите функции'}
                                                           title={'Дополнительные функции'}
                                                           availableChoices={availableAdditionalFunctions}/>

                                    <AddressInputField title={'Маршрут'} hint={'Выберите пункты отправки и получения'}
                                                       setPointA={setPointA} setPointB={setPointB}/>
                                </div>
                            </Accordion>

                            <div className={'flex flex-col gap-2'}>
                                <p>Стоимость: </p>
                                <input type={'text'}
                                       className={'p-2 ps-5 pe-5 bg-white w-full outline-none rounded-lg transition-all'}
                                       placeholder={'50 000'} onInput={(e) => {
                                    setReward(e.target.value)
                                }}/>
                            </div>

                            <Button onClick={createApplication}>Отправить заявку</Button>

                            {
                                error && (
                                    <div className={'p-2 bg-white rounded-xl'}>
                                        <p className={'text-red-500 text-center'}>{error}</p>
                                    </div>
                                )
                            }

                        </div>
                    )
                }
            </div>
        </>
    );
}

export default App;